import { UnitTour, UnitOfAreaNames } from '../../../../../types';
import { makeUnitInfoID } from '../../constants';

import { pluralize } from '../../../../../utils/pluralize/pluralize';

import styles from './UnitInfo.module.scss';

interface UnitInfoProps {
  unitInfo: UnitTour;
  hideUnitInformation?: boolean;
}

const BEDS_AND_BATHS_SEPARATOR = ', ';
const ZERO_BEDS_LABEL = 'Studio';

export default function UnitInfo({
  unitInfo,
  hideUnitInformation,
}: UnitInfoProps) {
  const bedsAndBaths = [
    ...(typeof unitInfo.numBed === 'number'
      ? unitInfo.numBed === 0
        ? [ZERO_BEDS_LABEL]
        : [`${unitInfo.numBed} ${pluralize('bed', unitInfo.numBed)}`]
      : []),

    ...(!!unitInfo.numBath
      ? [`${unitInfo.numBath} ${pluralize('bath', unitInfo.numBath)}`]
      : []),
  ];

  return (
    <div className={styles.container}>
      <div className={styles.infoPod}>
        {!!bedsAndBaths && (
          <div id={makeUnitInfoID('beds-baths')}>
            {bedsAndBaths.join(BEDS_AND_BATHS_SEPARATOR)}
          </div>
        )}
        {!!unitInfo.unitSize && !!unitInfo.unitSize.num && (
          <>
            {'|'}
            <div id={makeUnitInfoID('size')}>
              {unitInfo.unitSize.num}{' '}
              {UnitOfAreaNames[unitInfo.unitSize.unitOfArea]}
            </div>
          </>
        )}

        {!!unitInfo.unitNumber && !hideUnitInformation && (
          <>
            {'|'}
            <div id={makeUnitInfoID('unit-number')}>
              Unit {unitInfo.unitNumber}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
