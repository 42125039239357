import { CSSProperties, useEffect, useState, useMemo } from 'react';

import { ThemeProps } from '../../Theme';

import { getCarouselVisible } from '../../../stores/slices/ui';
import { useAppSelector } from '../../../hooks/redux';
import useFireInitialAnalyticsEvents from '../../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';
import useIsMobile from '../../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from './hooks/useIsViewportTooLow/useIsViewportTooLow';
import useMenuItemContent from './hooks/useMenuItemContent/useMenuItemContent';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { useNavigateToVideo } from '../../../hooks/useNavigateToVideo/useNavigateToVideo';
import { useNavigateToPhoto } from '../../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import useTourFunctionality from '../../../hooks/useTourFunctionality/useTourFunctionality';
import useURLParam, {
  OverlayURLParam,
} from '../../../hooks/useURLParam/useURLParam';

import isDarkTheme from '../../../utils/isDarkTheme/isDarkTheme';

import Banner from '../components/Banner/Banner';
import Carousel from './components/Carousel/Carousel';
import FloorPlan from '../../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import Hamburger from './components/Hamburger/Hamburger';
import MediaImageControls from '../../../components/MediaImageControls/MediaImageControls';
import MediaImage from '../../../components/MediaImage/MediaImage';
import MediaVideo from '../../../components/MediaVideo/MediaVideo';
import Pano from '../components/Pano/Pano';
import StartScreen from '../../../components/StartScreen/StartScreen';
import ViewControls from './components/ViewControls/ViewControls';

import styles from './GreystarTheme.module.scss';

export default function GreystarTheme({
  cssVariables,
  theme,
  tour,
}: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { availableTabs, logo } = useTourFunctionality(tour, theme);
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const media = useAppSelector((s) => s.media);
  const visible = useAppSelector(getCarouselVisible);
  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();
  const [showBanner] = useState(true);

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const isPortrait = useIsMobile('portrait');
  const { isViewportTooLow } = useIsViewportTooLow(tour);

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <>
      <GlobalCss />
      {!visible && <Hamburger />}
      <MenuItemContentProvider>
        <div
          data-cy="tb-greystar-default-theme"
          id="theme"
          style={
            {
              '--button-border-radius': '10px',
              '--button-border-radius-small': '5px',
              '--controls-border-radius': '50px',
            } as CSSProperties
          }
        >
          {media.type === 'pano' && (
            <FloorPlan
              configs={tour.floorplan}
              sliders={tour.sliders}
              additionalControls={
                isMobile || isViewportTooLow ? (
                  <FloorPlanMobileControls
                    theme={theme}
                    tour={tour}
                    isLandscape={isLandscape || isViewportTooLow}
                  />
                ) : null
              }
              darkTheme={true}
              banner={showBanner && !isPortrait ? <Banner /> : null}
            />
          )}
          <Banner className={styles.bannerPortrait} />
          <Carousel
            availableTabs={availableTabs}
            config={tour.carousel}
            initialItemType={'pano'}
            pillColors={{
              default: theme.styles.carousel.tabs.icons.default.color,
              active: theme.styles.carousel.tabs.icons.active.color,
            }}
            floorPlanPanoIds={floorPlanPanoIds}
            themeType={isDarkTheme(theme.styles.carousel.background)}
          />

          {media.type === 'pano' && (
            <Pano
              hideRightMenu={hideRightMenu}
              tour={tour}
              theme={theme}
              useMenuItemContent={useMenuItemContent}
              ViewControls={ViewControls}
            />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="bottom"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
                cssVariables={cssVariables}
              />
              <MediaImageControls tour={tour} />
            </>
          )}

          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="tb-pro"
              cssVariables={cssVariables}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}

          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
