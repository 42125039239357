import { useState } from 'react';
import clsx from 'clsx';
import { animated, useTransition } from '@react-spring/web';

import { ReactComponent as Close } from './assets/close.svg';

import styles from './Banner.module.scss';

interface BannerProps {
  className?: string;
}

export default function Banner({ className = '' }: BannerProps) {
  const [visible, setVisible] = useState(true);

  const containerAnimation = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className={clsx(styles.container, className)}>
      {containerAnimation((style, item) => {
        return (
          item && (
            <animated.div style={style} className={styles.animatedDiv}>
              {visible && (
                <div className={styles.text}>
                  Airbnb-firendly. Hostingpart-time allowed.
                </div>
              )}
              <div className={styles.button}>
                <Close onClick={() => setVisible((visible) => !visible)} />
              </div>
            </animated.div>
          )
        );
      })}
    </div>
  );
}
