import { ReactNode, useEffect, useState } from 'react';

import useViewer from '../../../../../hooks/useViewer/useViewer';
import { FloorPlan as FloorPlanConfig, Slider } from '../../../../../types';
import UnitFloorPlanControls from './components/UnitFloorPlanControls/UnitFloorPlanControls';
import UnitFloorPlanMap from './components/UnitFloorPlanMap/UnitFloorPlanMap';
import UnitInfo from '../UnitInfo/UnitInfo';
import { VisibilityState } from './types';
import { UnitTour } from '../../../../../types';

import styles from './UnitFloorPlan.module.scss';
import useIsMobile from '../../../../../hooks/useIsMobile/useIsMobile';
import usePanosSliders from '../../../../../hooks/usePanosSliders/usePanosSliders';

interface FloorPlanProps {
  configs: FloorPlanConfig[];
  sliders: Slider[];
  additionalControls?: ReactNode | ReactNode[];

  unitInfo: UnitTour;
  banner?: ReactNode;
}

const UnitFloorPlan = ({
  configs,
  sliders,
  additionalControls,

  unitInfo,
  banner,
}: FloorPlanProps) => {
  const { viewer, panoId, pov } = useViewer();
  const isMobile = useIsMobile();

  const [mapVisibility, setMapVisibility] = useState<VisibilityState>(
    isMobile ? 'hidden' : 'visible'
  );

  const [floorplanConfig, setFloorplanConfig] = useState<FloorPlanConfig>();

  useEffect(() => {
    const currentFloorplan = configs.find((currentConfig) => {
      return currentConfig.hotspots.some(
        (hotspot) => hotspot.panoid === panoId
      );
    })!;

    setFloorplanConfig(currentFloorplan);
  }, [configs, panoId, viewer]);

  const [sliderOpacity, setPanoSliderOpacity] = usePanosSliders(sliders);

  if (!viewer || !panoId || !pov) {
    return null;
  }

  if (!floorplanConfig && !additionalControls && !setPanoSliderOpacity)
    return null;

  return (
    <div className={styles.floorPlan} data-cy="floor-plan">
      <div className={styles.leftSide}>
        <UnitFloorPlanControls
          onVisibilityChange={floorplanConfig && setMapVisibility}
          onSliderChange={
            setPanoSliderOpacity
              ? (value) => setPanoSliderOpacity(1 - value)
              : undefined
          }
          sliderValue={1 - sliderOpacity}
          additionalControls={additionalControls}
          mapVisibility={mapVisibility}
        />
      </div>
      <div className={styles.rightSide}>
        <UnitInfo unitInfo={unitInfo} hideUnitInformation={false} />
        <div className={styles.rightSide}>
          {floorplanConfig && (
            <>
              <UnitFloorPlanMap
                currentPanoId={panoId}
                heading={pov.heading}
                hotspots={floorplanConfig.hotspots}
                mapImageUrl={floorplanConfig.img || floorplanConfig.image!.url}
                onPanoramaSelect={(selectedPanoId) => {
                  if (panoId === selectedPanoId) {
                    return;
                  }

                  viewer.setPano(selectedPanoId);
                }}
                visibility={mapVisibility}
              />
            </>
          )}
          {banner && <div className={styles.banner}>{banner}</div>}
        </div>
      </div>
    </div>
  );
};

export default UnitFloorPlan;
