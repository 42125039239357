import { CSSProperties, useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import useURLParam, {
  OverlayURLParam,
} from '../../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { ThemeProps } from '../../Theme';
import { getCarouselVisible } from '../../../stores/slices/ui';

import isDarkTheme from '../../../utils/isDarkTheme/isDarkTheme';

import Banner from '../components/Banner/Banner';
import Carousel from './components/Carousel/Carousel';
import FloorPlan from '../../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import Pano from '../components/Pano/Pano';
import StartScreen from '../../../components/StartScreen/StartScreen';
import ViewControls from './components/ViewControls/ViewControls';
import useIsMobile from '../../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from './hooks/useIsViewportTooLow/useIsViewportTooLow';
import useTourFunctionality from '../../../hooks/useTourFunctionality/useTourFunctionality';
import Hamburger from './components/Hamburger/Hamburger';
import useFireInitialAnalyticsEvents from '../../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';
import useMenuItemContent from './hooks/useMenuItemContent/useMenuItemContent';

import styles from './GreystarSimpleTheme.module.scss';

export default function GreystarSimpleTheme({ theme, tour }: ThemeProps) {
  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const isPortrait = useIsMobile('portrait');
  const { isViewportTooLow } = useIsViewportTooLow(tour);
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const [showBanner] = useState(true);
  const { logo } = useTourFunctionality(tour, theme);
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const visible = useAppSelector(getCarouselVisible);

  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  return (
    <>
      <GlobalCss />
      {!visible && <Hamburger />}
      <MenuItemContentProvider>
        <div
          data-cy="tb-greystar-default-theme"
          id="theme"
          style={
            {
              '--button-border-radius': '10px',
              '--button-border-radius-small': '5px',
              '--controls-border-radius': '50px',
            } as CSSProperties
          }
        >
          <FloorPlan
            configs={tour.floorplan}
            sliders={tour.sliders}
            additionalControls={
              isMobile || isViewportTooLow ? (
                <FloorPlanMobileControls
                  theme={theme}
                  tour={tour}
                  isLandscape={isLandscape || isViewportTooLow}
                />
              ) : null
            }
            darkTheme={true}
            banner={showBanner && !isPortrait ? <Banner /> : null}
          />
          <Banner className={styles.bannerPortrait} />
          <Carousel
            config={tour.carousel}
            themeType={isDarkTheme(theme.styles.carousel.background)}
          />
          <Pano
            hideRightMenu={hideRightMenu}
            tour={tour}
            theme={theme}
            useMenuItemContent={useMenuItemContent}
            ViewControls={ViewControls}
          />
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
